import IconLink from 'Atoms/Links/IconLink';
import HeaderLinkModel from 'Models/Headers/HeaderLinkModel.interface';
import { useEffect, useRef, useState } from 'react';
import useMedia from 'Shared/Hooks/useMedia';
import { styled } from 'stitches.config';
import { animation, timings } from 'Theme/Settings/animation';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { useHeaderData } from '../Header';
import MobileMenuItem, { Depth } from './MenuItem/MobileMenuItem';
import NavigationOverlay from './NavigationOverlay.tsx/NavigationOverlay';
import useMountTransition from 'Shared/Hooks/useMountTransition';

import { canUseDOM } from 'Shared/Common/Helpers';

type PropTypes = {
  isOpen: boolean;
  onMenuClose: () => void;

  menuId?: number | null;
};

function MobileNavigationMenu({ isOpen, onMenuClose }: PropTypes) {
  const { commerceLinks, topLinks } = useHeaderData();

  const [activeId, setActiveId] = useState<null | number>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const isMobile = useMedia(mediaQueryTypes.mediaMaxLarge);

  const currentUrl = canUseDOM() && window.location.href;

  useEffect(() => {
    onMenuClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl]);

  const hasTransitionedIn = useMountTransition(isOpen, 250);

  return (
    <>
      <Wrapper top={isMobile} ref={menuRef} isOpen={isOpen}>
        <List>
          {commerceLinks &&
            commerceLinks.map((item: HeaderLinkModel) => (
              <li key={item.id}>
                <MobileMenuItem
                  item={item}
                  toggleMenu={onMenuClose}
                  activeId={activeId}
                  setActive={(id) => setActiveId(id)}
                  expandedByDefault={false}
                  depth={item.iteration as Depth}
                />
              </li>
            ))}
        </List>
        <BottomLinkContainer>
          {topLinks &&
            topLinks.map((item: HeaderLinkModel) => (
              <StyledIconLink key={item.id} type={'Arrow'} href={item.href}>
                {item.text}
              </StyledIconLink>
            ))}
        </BottomLinkContainer>
      </Wrapper>
      {(hasTransitionedIn || isOpen) && (
        <NavigationOverlay isVisible={hasTransitionedIn && isOpen} />
      )}
    </>
  );
}

export default MobileNavigationMenu;

const StyledIconLink = styled(IconLink, {
  my: 2,
  fontFamily: '$fontSecondary500',
  lineHeight: '$lh15',
});

const BottomLinkContainer = styled('div', {
  m: 4,
  pb: 4,
});

const Wrapper = styled('div', {
  position: 'fixed',
  l: 0,
  backgroundColor: '$navigationBackgroundPrimary',
  h: '100%',
  w: '100%',
  zIndex: '$Flyout',
  transform: 'translateX(-100%)',
  variants: {
    top: {
      true: {
        t: '$mobileHeaderHeight',
      },
    },
    isOpen: {
      true: {
        transform: 'translateX(0)',
      },
    },
  },
  transition: `all ${timings.threeTenths} ${animation.timingFn}`,
});

const List = styled('ul', {
  listStyle: 'none',
  p: 0,
  m: 0,
  overflowY: 'scroll',
  maxHeight: '100vh',
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    display: 'none',
  },
  scrollbarWidth: 'none',
  '@mediaMinSmall': {
    maxHeight: 'calc(100vh - 100px)',
  },
});
