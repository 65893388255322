import Image from 'Atoms/Image/Image';
import ImageModel from 'Models/Assets/ImageModel.interface';
import { useState } from 'react';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import useMedia from 'Shared/Hooks/useMedia';
import KexLink from 'Kex/KexLink';
import {
  animateUnderlineActive,
  animateUnderlineNotActive,
} from 'Shared/Common/animateUnderline';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';

type PropTypes = {
  onClick?: () => void;
  href?: string;
  image: ImageModel;
  text: string;
  iteration: number;
  id: number;
};

function NavigationIconLink({
  onClick,
  href = '',
  image,
  text = '',
  iteration,
  id,
}: PropTypes) {
  const isMobile = useMedia(mediaQueryTypes.mediaMaxLarge);
  const [linkIsHovered, setLinkIsHovered] = useState(false);
  const { pageId } = useCurrentPage<PageModelBase>();
  const hasUserNavigatedToLink = id === pageId;

  const isLargeImage = iteration <= 3;
  return (
    <StyledLinkWrapper
      onMouseOver={() => setLinkIsHovered(true)}
      onMouseLeave={() => setLinkIsHovered(false)}
    >
      <KexLink
        css={KexLinkStyle}
        onClick={onClick}
        href={href}
        fontFamily={isMobile ? 'secondary400' : 'secondary500'}
      >
        <IconWrapper data-linkhovered={linkIsHovered}>
          {image && (
            <Image
              src={image?.src}
              alt={image?.alt}
              fixedWidth={isMobile ? (isLargeImage ? 80 : 32) : undefined}
              fixedHeight={isMobile ? (isLargeImage ? 64 : 32) : undefined}
            />
          )}
        </IconWrapper>
        <Text linkIsHovered={linkIsHovered || hasUserNavigatedToLink}>
          {text}
        </Text>
      </KexLink>
    </StyledLinkWrapper>
  );
}

const KexLinkStyle = {
  display: 'flex',
  fontFamily: '$fontSecondary400',
  gap: 16,
  fs: 8,
  lineHeight: '$lh171',
  '@mediaMinLarge': {
    display: 'inline-flex',
    fs: 7,
    gap: 8,
  },
};

const IconWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  '@mediaMinLarge': {
    ml: 1,
    flexShrink: 0,
  },
});

const StyledLinkWrapper = styled('div', {
  p: 2,
  pl: 0,
  '@mediaMinLarge': {
    p: 2,
  },
});

const Text = styled('span', {
  whiteSpace: 'nowrap',
  ...animateUnderlineNotActive,
  alignSelf: 'center',
  variants: {
    linkIsHovered: {
      true: {
        ...animateUnderlineActive,
      },
    },
  },
});

export default NavigationIconLink;
