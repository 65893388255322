import Image from 'Atoms/Image/Image';
import KexLink from 'Kex/KexLink';
import { useState } from 'react';
import useMedia from 'Shared/Hooks/useMedia';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import {
  animateUnderlineActive,
  animateUnderlineNotActive,
} from 'Shared/Common/animateUnderline';
import ArrowIcon from '../../../../Atoms/Icons/ArrowIcon';

type PropTypes = {
  text: string;
  imageHref: string;
  href: string;
  large?: boolean;
  onClick?: () => void;
};

function NavigationImageLink({
  text = '',
  imageHref = '',
  href = '/',
  large = false,
  onClick,
}: PropTypes) {
  const [linkIsHovered, setLinkIsHovered] = useState(false);
  const isMobile = useMedia(mediaQueryTypes.mediaMaxLarge);

  return (
    <div
      onMouseOver={() => setLinkIsHovered(true)}
      onMouseLeave={() => setLinkIsHovered(false)}
    >
      <StyledKexLink
        href={!onClick ? href : ''}
        fontFamily={isMobile ? 'secondary400' : 'primary400'}
        underlined={false}
      >
        <Wrapper large={large}>
          <ImageWrapper
            large={large}
            css={!imageHref ? (isMobile ? { display: 'none' } : {}) : {}}
          >
            <Image src={imageHref} alt={'image'} />
          </ImageWrapper>
          <StyledLinkWrapper onClick={onClick && onClick}>
            <Text linkIsHovered={linkIsHovered}>{text}</Text>
            {!isMobile && (
              <IconWrapper data-linkhovered={linkIsHovered}>
                <ArrowIcon size={'m'} color={'primary'} rotateLeft />
              </IconWrapper>
            )}
          </StyledLinkWrapper>
        </Wrapper>
      </StyledKexLink>
    </div>
  );
}

const StyledKexLink = styled(KexLink, {
  w: '100%',
});

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  variants: {
    large: {
      true: { flexDirection: 'column' },
    },
  },
});

const StyledLinkWrapper = styled('div', {
  width: 'fit-content',
  display: 'flex',
  textTransform: 'capitalize',
  '@mediaMinLarge': {
    textTransform: 'uppercase',
  },
});

const IconWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  m: 'auto',
  ml: 2,
});

const ImageWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  maxWidth: '192px',
  w: '100%',
  height: 151,
  variants: {
    large: {
      true: {
        '@mediaMinLarge': {
          backgroundColor: '$primary5',
          mb: 5,
        },
      },
      false: {
        flexDirection: 'column',
        width: '80px',
        height: '64px',
        mr: 4,
        '@mediaMinLarge': {
          mr: 6,
        },
      },
    },
  },
});

const Text = styled('span', {
  textTransform: 'lowercase',
  '@mediaMinLarge': {
    textTransform: 'uppercase',
  },
  ...animateUnderlineNotActive,
  variants: {
    linkIsHovered: {
      true: {
        ...animateUnderlineActive,
      },
    },
  },
});

export default NavigationImageLink;
