import { ChevronIcon } from 'Atoms/Icons';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';
import HeaderLinkModel from 'Models/Headers/HeaderLinkModel.interface';
import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';
import { useCallback, useEffect, useRef, useState } from 'react';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import useMedia from 'Shared/Hooks/useMedia';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';
import { styled } from 'stitches.config';
import { animation, timings } from 'Theme/Settings/animation';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import NavigationIconLink from '../NavigationIconLink/NavigationIconLink';
import NavigationImageLink from '../NavigationImageLink/NavigationImageLink';

export type Depth = 2 | 3 | 4;

type PropTypes = {
  item: HeaderLinkModel;
  toggleMenu?: () => void;
  activeId: null | number;
  setActive: (id: number) => void;
  depth: Depth;
  isProfileLinks?: boolean;
  expandedByDefault?: boolean;
};

function MobileMenuItem({
  item,
  toggleMenu,
  activeId,
  setActive,
  depth,
  expandedByDefault = false,
}: PropTypes) {
  const {
    user: { authenticated },
  } = useUserStateData();
  const { pageType, pageId } = useCurrentPage<PageModelBase>();

  const isMobile = useMedia(mediaQueryTypes.mediaMaxLarge);
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false);
  const [innerHeight, setInnerHeight] = useState<boolean>(false);
  const subLinkContainerRef = useRef<HTMLDivElement>(null);
  const hasSubLinks =
    item.subLinks && !!item.subLinks.length && item.iteration <= 3;

  const kexNavigate = useKexNavigate();

  const findActiveNode = useCallback(
    (subs: HeaderLinkModel[]) => {
      hasSubLinks &&
        subs.map((item: HeaderLinkModel) =>
          pageId === item.id
            ? setSubMenuOpen(true)
            : item.subLinks.length > 0 && findActiveNode(item.subLinks)
        );
    },
    [hasSubLinks, pageId]
  );

  useEffect(() => {
    if (pageType === 'StartPage') {
      if (!expandedByDefault) {
        setSubMenuOpen(false);
        setInnerHeight(false);
      }
      setActive(-1);
    } else {
      if (subMenuOpen) {
        setInnerHeight(true);
      }
    }
    if (!expandedByDefault) {
      pageId === item.id
        ? setActive(item.id)
        : hasSubLinks && findActiveNode(item.subLinks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageId,
    item,
    expandedByDefault,
    findActiveNode,
    hasSubLinks,
    pageType,
    setActive,
  ]);

  const onToggle = () => {
    if (!innerHeight) {
      setInnerHeight(true);
    } else {
      setTimeout(() => setInnerHeight(false), 300);
    }
    setSubMenuOpen(!subMenuOpen);
  };

  const navigateToLink = () => {
    toggleMenu && toggleMenu();
    kexNavigate(item.href);
  };

  return (
    <Item>
      <OuterWrapper depth={depth} isOpened={subMenuOpen && depth === 2}>
        {item.navigationImage ? (
          depth !== 3 && depth !== 4 ? (
            <NavigationImageLink
              text={item.text}
              imageHref={
                item.navigationImage?.src + '?preset=mobile-menu-image'
              }
              href={item.href}
              onClick={navigateToLink}
            />
          ) : (
            <NavigationIconLink
              image={item.navigationImage}
              text={item.text}
              onClick={navigateToLink}
              href={item?.href}
              iteration={item.iteration}
              id={item.id}
            />
          )
        ) : (
          <LinkTextWrapper onClick={navigateToLink}>
            {item.text}
          </LinkTextWrapper>
        )}
        {((!expandedByDefault && hasSubLinks) ||
          (!expandedByDefault && authenticated && isMobile && hasSubLinks)) && (
          <SubMenuButton
            onClick={onToggle}
            aria-label={'submenu toggle button'}
          >
            {/* Ugly but needed because of useOutsideClick behavior which would otherwise click through */}
            <IconWrapper isSubMenuOpen={!subMenuOpen}>
              <ChevronIcon
                size="m"
                color="secondary"
                css={{ transform: 'scaleY(-1)' }}
              />
            </IconWrapper>
            <IconWrapper isSubMenuOpen={subMenuOpen}>
              <ChevronIcon size="m" color="secondary" />
            </IconWrapper>
          </SubMenuButton>
        )}
      </OuterWrapper>
      {hasSubLinks && (
        <SubLinkContainer
          ref={subLinkContainerRef}
          isOpen={subMenuOpen}
          innerHeight={innerHeight}
        >
          {item.subLinks.map((item: HeaderLinkModel) => {
            return (
              <MobileMenuItem
                key={item.linkId}
                item={item}
                toggleMenu={toggleMenu}
                activeId={activeId}
                setActive={setActive}
                depth={item.iteration as Depth}
              />
            );
          })}
        </SubLinkContainer>
      )}
    </Item>
  );
}

const IconWrapper = styled('div', {
  marginLeft: 'auto',
  variants: {
    isSubMenuOpen: {
      true: {
        display: 'none',
      },
      false: {
        display: 'block',
      },
    },
  },
});

const LinkTextWrapper = styled('div', {
  my: 'auto',
  px: 2,
  py: 3.5,
  lineHeight: '$lh125',
  fontFamily: '$fontSecondary500',
  color: '$textPrimary',
  fs: 8,
});

const OuterWrapper = styled('div', {
  display: 'flex',
  backgroundColor: '$navigationBackgroundSecondary',
  my: 0.5,
  py: 2,
  pl: 2,
  pr: 3,
  variants: {
    depth: {
      2: {
        pl: 2,
      },
      3: {
        pl: 6,
      },
      4: {
        pl: 8,
      },
    },
    isOpened: {
      true: {
        backgroundColor: '$primary2',
      },
    },
  },
});

const SubLinkContainer = styled('div', {
  maxHeight: 0,
  position: 'relative',
  display: 'block',
  opacity: 0,
  overflowY: 'hidden',
  transitionDuration: timings.threeTenths,
  transitionTimingFunction: animation.timingFn,
  transitionProperty: 'opacity',
  variants: {
    isOpen: {
      true: {
        opacity: 1,
      },
    },
    innerHeight: {
      true: {
        maxHeight: 'unset',
      },
    },
  },
});

const Item = styled('div', {
  cursor: 'pointer',
  display: 'flex',
  position: 'relative',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  flexDirection: 'column',
  height: 'auto',
});

const SubMenuButton = styled('button', {
  bw: 0,
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ':active': {
    outlineWidth: 0,
  },
  ':focus': {
    outlineWidth: 0,
  },
});

export default MobileMenuItem;
