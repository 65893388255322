import usePreventBackgroundScroll from 'Shared/Hooks/usePreventBackgroundScroll';
import { styled } from 'stitches.config';

type PropTypes = {
  isVisible: boolean;
};

const NavigationOverlay = ({ isVisible }: PropTypes) => {
  usePreventBackgroundScroll();

  return <MenuOverlay isVisible={isVisible} />;
};

export default NavigationOverlay;

const MenuOverlay = styled('div', {
  w: '100vw',
  h: '100vh',
  position: 'fixed',
  l: 0,
  t: 0,
  opacity: '0',
  zIndex: '$OverlayMegaMenu',
  transition: 'all 0.25s ease-in-out',
  visibility: 'hidden',
  backgroundColor: '$modalOverlaySecondary',
  variants: {
    isVisible: {
      true: {
        visibility: 'initial',
        opacity: '0.5',
      },
    },
  },
});
